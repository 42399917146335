import React, { useState, useRef } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import { StyledWrapper, ThemeCard } from '../components/Resources/Resources.styles';
import { Layout } from '../components/Layout/Layout';
import { FeaturedIn } from '../components/FeaturedIn/FeaturedIn';
import card1 from '../components/images/artboard1.png';
import card2 from '../components/images/artboard2.png';
import card3 from '../components/images/artboard3.png';
import card4 from '../components/images/artboard4.png';
import card5 from '../components/images/loopdesign2.png';
import card6 from '../components/images/artboardng1.png';
import card7 from '../components/images/artboardng2.png';
import card8 from '../components/images/aloopproxfront.png';
// import advert from "../components/images/cardRectangle.png";

export const Items = () => {
  return (
    <>
      <div className="mt-14 grid max-w-6xl grid-cols-1 gap-5 mx-auto sm:gap-6 lg:gap-10 sm:grid-cols-2 md:grid-cols-3">
        <div className="relative row res active" id="premium">
          <div className="absolute -inset-2">
            <div
              className="w-full h-full max-w-6xl mx-auto opacity-30 blur-lg filter 
              bg-gradient-to-r
              from-yellow-400
              via-pink-500
              to-green-600"
            ></div>
          </div>
          <div className="p-2 relative bg-white rounded-xl">
            <div className="w-full overflow-hidden">
              <img
                src={card8}
                alt="PACKAGE"
                className="h-48 object-cover box-border rounded w-full"
              />
            </div>
            <div className="p-8 sm:pb-2 sm:pt-4 sm:px-4 flex flex-col">
              <h3 className="text-xl font-bold text-gray-900 font-pj">Loop PRO X</h3>
              <p className="mt-1 text-base font-normal leading-7 text-gray-600">
                A design system for busy designers. Built with a robust architecture and ready to
                use components.
              </p>
              <div className="flex justify-between mt-auto">
                <div className="flex items-end">
                  <a
                    href="#"
                    className="inline-flex items-center justify-center px-2 py-0.5 text-sm transition-all duration-200 bg-gray-200 text-gray-700 border border-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj rounded-lg"
                    role="button"
                  >
                    Pro
                  </a>
                </div>
                <Link
                  to="/product/loopprox/"
                  className="inline-flex items-center justify-center px-2 py-2 text-base font-bold text-white transition-all duration-200 bg-gray-900 border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj hover:bg-gray-600 rounded-xl"
                  role="button"
                >
                  Download
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="relative row res active" id="free">
          <div className="absolute -inset-2">
            <div
              className="w-full h-full max-w-6xl mx-auto opacity-30 blur-lg filter 
              bg-gradient-to-r
              from-yellow-400
              via-pink-500
              to-green-600"
            ></div>
          </div>
          <div className="p-2 relative bg-white rounded-xl">
            <div className="w-full overflow-hidden">
              <img
                src={card1}
                alt="PACKAGE"
                className="h-48 object-cover box-border rounded w-full"
              />
            </div>
            <div className="p-8 sm:pb-2 sm:pt-4 sm:px-4 flex flex-col">
              <h3 className="text-xl font-bold text-gray-900 font-pj">
                Contrast Design Bootstrap React
              </h3>
              <p className="mt-1 text-base font-normal leading-7 text-gray-600">
                Bootstrap React UI Kit and reusable components for building mobile-first, responsive
                webistes and web apps
              </p>
              <div className="flex justify-between mt-auto">
                <div className="flex items-end">
                  <a
                    href="#"
                    className="inline-flex items-center justify-center px-2 py-0.5 text-sm transition-all duration-200 bg-gray-200 text-gray-700 border border-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj rounded-lg"
                    role="button"
                  >
                    Free
                  </a>
                </div>
                <Link
                  to="/product/contrast/"
                  className="inline-flex items-center justify-center px-2 py-2 text-base font-bold text-white transition-all duration-200 bg-gray-900 border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj hover:bg-gray-600 rounded-xl"
                  role="button"
                >
                  Download
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="relative row res active" id="free">
          <div className="absolute -inset-2">
            <div
              className="w-full h-full max-w-6xl mx-auto opacity-30 blur-lg filter 
              bg-gradient-to-r
              from-yellow-400
              via-pink-500
              to-green-600"
            ></div>
          </div>
          <div className="p-2 relative bg-white rounded-xl">
            <div className="w-full overflow-hidden">
              <img
                src={card3}
                alt="PACKAGE"
                className="h-48 object-cover box-border rounded w-full"
              />
            </div>
            <div className="p-8 sm:pb-2 sm:pt-4 sm:px-4 flex flex-col">
              <h3 className="text-xl font-bold text-gray-900 font-pj">
                Contrast React Admin Dashboard
              </h3>
              <p className="mt-1 text-base font-normal leading-7 text-gray-600">
                Beautifully designed, responsive admin dashboards to power your admin websites and
                apps.
              </p>
              <div className="flex justify-between mt-auto">
                <div className="flex items-end">
                  <a
                    href="#"
                    className="inline-flex items-center justify-center px-2 py-0.5 text-sm transition-all duration-200 bg-gray-200 text-gray-700 border border-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj rounded-lg"
                    role="button"
                  >
                    Free
                  </a>
                </div>
                <Link
                  to="/product/admin-contrast/"
                  className="inline-flex items-center justify-center px-2 py-2 text-base font-bold text-white transition-all duration-200 bg-gray-900 border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj hover:bg-gray-600 rounded-xl"
                  role="button"
                >
                  Download
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="relative row res active" id="premium">
          <div className="absolute -inset-2">
            <div
              className="w-full h-full max-w-6xl mx-auto opacity-30 blur-lg filter 
              bg-gradient-to-r
              from-yellow-400
              via-pink-500
              to-green-600"
            ></div>
          </div>
          <div className="p-2 relative bg-white rounded-xl">
            <div className="w-full overflow-hidden">
              <img
                src={card2}
                alt="PACKAGE"
                className="h-48 object-cover box-border rounded w-full"
              />
            </div>
            <div className="p-8 sm:pb-2 sm:pt-4 sm:px-4 flex flex-col">
              <h3 className="text-xl font-bold text-gray-900 font-pj">
                Contrast Bootstrap React Pro
              </h3>
              <p className="mt-1 text-base font-normal leading-7 text-gray-600">
                Bootstrap React Pro UI Kit and reusable components for building mobile-first,
                responsive webistes and web apps
              </p>
              <div className="flex justify-between mt-auto">
                <div className="flex items-end">
                  <a
                    href="#"
                    className="inline-flex items-center justify-center px-2 py-0.5 text-sm transition-all duration-200 bg-gray-200 text-gray-700 border border-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj rounded-lg"
                    role="button"
                  >
                    Pro
                  </a>
                </div>
                <Link
                  to="/product/contrast-pro/"
                  className="inline-flex items-center justify-center px-2 py-2 text-base font-bold text-white transition-all duration-200 bg-gray-900 border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj hover:bg-gray-600 rounded-xl"
                  role="button"
                >
                  Download
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="relative row res active" id="free">
          <div className="absolute -inset-2">
            <div
              className="w-full h-full max-w-6xl mx-auto opacity-30 blur-lg filter 
              bg-gradient-to-r
              from-yellow-400
              via-pink-500
              to-green-600"
            ></div>
          </div>
          <div className="p-2 relative bg-white rounded-xl">
            <div className="w-full overflow-hidden">
              <img
                src={card5}
                alt="PACKAGE"
                className="h-48 object-cover box-border rounded w-full"
              />
            </div>
            <div className="p-8 sm:pb-2 sm:pt-4 sm:px-4 flex flex-col">
              <h3 className="text-xl font-bold text-gray-900 font-pj">
                Loop - Design System UI Kit v2
              </h3>
              <p className="mt-1 text-base font-normal leading-7 text-gray-600">
                A free design system for busy designers. Built with a robust architecture and ready
                to use components.
              </p>
              <div className="flex justify-between mt-auto">
                <div className="flex items-end">
                  <a
                    href="#"
                    className="inline-flex items-center justify-center px-2 py-0.5 text-sm transition-all duration-200 bg-gray-200 text-gray-700 border border-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj rounded-lg"
                    role="button"
                  >
                    Free
                  </a>
                </div>
                <Link
                  to="/product/loop/"
                  className="inline-flex items-center justify-center px-2 py-2 text-base font-bold text-white transition-all duration-200 bg-gray-900 border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj hover:bg-gray-600 rounded-xl"
                  role="button"
                >
                  Download
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="relative row res active" id="free">
          <div className="absolute -inset-2">
            <div
              className="w-full h-full max-w-6xl mx-auto opacity-30 blur-lg filter 
              bg-gradient-to-r
              from-yellow-400
              via-pink-500
              to-green-600"
            ></div>
          </div>
          <div className="p-2 relative bg-white rounded-xl">
            <div className="w-full overflow-hidden">
              <img
                src={card6}
                alt="PACKAGE"
                className="h-48 object-cover box-border rounded w-full"
              />
            </div>
            <div className="p-8 sm:pb-2 sm:pt-4 sm:px-4 flex flex-col">
              <h3 className="text-xl font-bold text-gray-900 font-pj">
                Contrast Design Bootstrap Angular
              </h3>
              <p className="mt-1 text-base font-normal leading-7 text-gray-600">
                Bootstrap Angular UI Kit and reusable components for building mobile-first,
                responsive webistes and web apps
              </p>
              <div className="flex justify-between mt-auto">
                <div className="flex items-end">
                  <a
                    href="#"
                    className="inline-flex items-center justify-center px-2 py-0.5 text-sm transition-all duration-200 bg-gray-200 text-gray-700 border border-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj rounded-lg"
                    role="button"
                  >
                    Free
                  </a>
                </div>
                <Link
                  to="/product/angular-contrast/"
                  className="inline-flex items-center justify-center px-2 py-2 text-base font-bold text-white transition-all duration-200 bg-gray-900 border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj hover:bg-gray-600 rounded-xl"
                  role="button"
                >
                  Download
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="relative row res active" id="free">
          <div className="absolute -inset-2">
            <div
              className="w-full h-full max-w-6xl mx-auto opacity-30 blur-lg filter 
              bg-gradient-to-r
              from-yellow-400
              via-pink-500
              to-green-600"
            ></div>
          </div>
          <div className="p-2 relative bg-white rounded-xl">
            <div className="w-full overflow-hidden">
              <img
                src={card7}
                alt="PACKAGE"
                className="h-48 object-cover box-border rounded w-full"
              />
            </div>
            <div className="p-8 sm:pb-2 sm:pt-4 sm:px-4 flex flex-col">
              <h3 className="text-xl font-bold text-gray-900 font-pj">
                Contrast Angular Admin Dashboard
              </h3>
              <p className="mt-1 text-base font-normal leading-7 text-gray-600">
                Bootstrap Angular Beautifully designed, responsive admin dashboards to power your
                admin websites and apps.
              </p>
              <div className="flex justify-between mt-auto">
                <div className="flex items-end">
                  <a
                    href="#"
                    className="inline-flex items-center justify-center px-2 py-0.5 text-sm transition-all duration-200 bg-gray-200 text-gray-700 border border-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj rounded-lg"
                    role="button"
                  >
                    Free
                  </a>
                </div>
                <Link
                  to="/product/angular-admin-contrast/"
                  className="inline-flex items-center justify-center px-2 py-2 text-base font-bold text-white transition-all duration-200 bg-gray-900 border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj hover:bg-gray-600 rounded-xl"
                  role="button"
                >
                  Download
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="relative row res active" id="premium">
          <div className="absolute -inset-2">
            <div
              className="w-full h-full max-w-6xl mx-auto opacity-30 blur-lg filter 
              bg-gradient-to-r
              from-yellow-400
              via-pink-500
              to-green-600"
            ></div>
          </div>
          <div className="p-2 relative bg-white rounded-xl">
            <div className="w-full overflow-hidden">
              <img
                src={card7}
                alt="PACKAGE"
                className="h-48 object-cover box-border rounded w-full"
              />
            </div>
            <div className="p-8 sm:pb-2 sm:pt-4 sm:px-4 flex flex-col">
              <h3 className="text-xl font-bold text-gray-900 font-pj">
                Contrast Design Bootstrap Angular
              </h3>
              <p className="mt-1 text-base font-normal leading-7 text-gray-600">
                Beautifully designed, responsive UI Kits and reusable components for building
                mobile-first, responsive webistes and web apps
              </p>
              <div className="flex justify-between mt-auto">
                <div className="flex items-end">
                  <a
                    href="#"
                    className="inline-flex items-center justify-center px-2 py-0.5 text-sm transition-all duration-200 bg-gray-200 text-gray-700 border border-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj rounded-lg"
                    role="button"
                  >
                    Pro
                  </a>
                </div>
                <Link
                  to="/product/angular-contrast-pro/"
                  className="inline-flex items-center justify-center px-2 py-2 text-base font-bold text-white transition-all duration-200 bg-gray-900 border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj hover:bg-gray-600 rounded-xl"
                  role="button"
                >
                  Download
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="relative row res active" id="free">
          <div className="absolute -inset-2">
            <div
              className="w-full h-full max-w-6xl mx-auto opacity-30 blur-lg filter 
              bg-gradient-to-r
              from-yellow-400
              via-pink-500
              to-green-600"
            ></div>
          </div>
          <div className="p-2 relative bg-white rounded-xl">
            <div className="w-full overflow-hidden">
              <img
                src={card2}
                alt="PACKAGE"
                className="h-48 object-cover box-border rounded w-full"
              />
            </div>
            <div className="p-8 sm:pb-2 sm:pt-4 sm:px-4 flex flex-col">
              <h3 className="text-xl font-bold text-gray-900 font-pj">Contrast Design Bootstrap</h3>
              <p className="mt-1 text-base font-normal leading-7 text-gray-600">
                Bootstrap UI Kit and reusable components for building mobile-first, responsive
                webistes and web apps
              </p>
              <div className="flex justify-between mt-auto">
                <div className="flex items-end">
                  <a
                    href="#"
                    className="inline-flex items-center justify-center px-2 py-0.5 text-sm transition-all duration-200 bg-gray-200 text-gray-700 border border-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj rounded-lg"
                    role="button"
                  >
                    Free
                  </a>
                </div>
                <Link
                  to="/product/bootstrap-contrast/"
                  className="inline-flex items-center justify-center px-2 py-2 text-base font-bold text-white transition-all duration-200 bg-gray-900 border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj hover:bg-gray-600 rounded-xl"
                  role="button"
                >
                  Download
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="relative row res active" id="free">
          <div className="absolute -inset-2">
            <div
              className="w-full h-full max-w-6xl mx-auto opacity-30 blur-lg filter 
              bg-gradient-to-r
              from-yellow-400
              via-pink-500
              to-green-600"
            ></div>
          </div>
          <div className="p-2 relative bg-white rounded-xl">
            <div className="w-full overflow-hidden">
              <img
                src={card2}
                alt="PACKAGE"
                className="h-48 object-cover box-border rounded w-full"
              />
            </div>
            <div className="p-8 sm:pb-2 sm:pt-4 sm:px-4 flex flex-col">
              <h3 className="text-xl font-bold text-gray-900 font-pj">
                Contrast Design Admin Dashboard
              </h3>
              <p className="mt-1 text-base font-normal leading-7 text-gray-600">
                Bootstrap Admin Dashboards to power your admin websites and apps.
              </p>
              <div className="flex justify-between mt-auto">
                <div className="flex items-end">
                  <a
                    href="#"
                    className="inline-flex items-center justify-center px-2 py-0.5 text-sm transition-all duration-200 bg-gray-200 text-gray-700 border border-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj rounded-lg"
                    role="button"
                  >
                    Free
                  </a>
                </div>
                <Link
                  to="/product/bootstrap-admin-contrast/"
                  className="inline-flex items-center justify-center px-2 py-2 text-base font-bold text-white transition-all duration-200 bg-gray-900 border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj hover:bg-gray-600 rounded-xl"
                  role="button"
                >
                  Download
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="relative row res active" id="premium">
          <div className="absolute -inset-2">
            <div
              className="w-full h-full max-w-6xl mx-auto opacity-30 blur-lg filter 
              bg-gradient-to-r
              from-yellow-400
              via-pink-500
              to-green-600"
            ></div>
          </div>
          <div className="p-2 relative bg-white rounded-xl">
            <div className="w-full overflow-hidden">
              <img
                src={card1}
                alt="PACKAGE"
                className="h-48 object-cover box-border rounded w-full"
              />
            </div>
            <div className="p-8 sm:pb-2 sm:pt-4 sm:px-4 flex flex-col">
              <h3 className="text-xl font-bold text-gray-900 font-pj">Contrast Design Bootstrap</h3>
              <p className="mt-1 text-base font-normal leading-7 text-gray-600">
                Bootstrap UI Kit and reusable components for building mobile-first, responsive
                webistes and web apps
              </p>
              <div className="flex justify-between mt-auto">
                <div className="flex items-end">
                  <a
                    href="#"
                    className="inline-flex items-center justify-center px-2 py-0.5 text-sm transition-all duration-200 bg-gray-200 text-gray-700 border border-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj rounded-lg"
                    role="button"
                  >
                    Pro
                  </a>
                </div>
                <Link
                  to="/product/bootstrap-contrast-pro/"
                  className="inline-flex items-center justify-center px-2 py-2 text-base font-bold text-white transition-all duration-200 bg-gray-900 border border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj hover:bg-gray-600 rounded-xl"
                  role="button"
                >
                  Download
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ResourcesPage = () => {
  const setClass = (list, key) => {
    list.forEach(item => {
      key === item.id ? item.classList.add('active') : item.classList.remove('active');
    });
  };

  const setActiveTab = e => {
    const a = document.querySelectorAll('.res.nav-link');
    const b = document.querySelectorAll('.res.row');
    if (e.target.id === 'all') {
      setClass(a, e.target.id);
      b.forEach(item => {
        item.classList.add('active');
      });
    } else {
      setClass(a, e.target.id);
      setClass(b, e.target.id);
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Resources - Devwares </title>
        <meta name="title" content="Resources - Devwares" />
        <meta
          name="description"
          content="High quality tools and resources for developers and designers"
        />
        <meta property="og:title" content="Resources - Devwares" />
        <meta
          property="og:description"
          content="High quality tools and resources for developers and designers"
        />
        <meta property="twitter:title" content="Resources - Devwares" />
        <meta
          property="twitter:description"
          content="High quality tools and resources for developers and designers"
        />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@devwares" />
        <meta name="twitter:creator" content="@devwares" />
      </Helmet>
      <StyledWrapper>
        <div className="box">
          <div className="box">
            <div className="header">Resources</div>
            <div className="body">Get access to various resources we are developing</div>
          </div>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                className="nav-link res active"
                id="all"
                onClick={setActiveTab}
                data-toggle="tab"
                href="#all"
              >
                All Resources
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link res"
                id="free"
                onClick={setActiveTab}
                data-toggle="tab"
                href="#free"
              >
                Free Resources
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link res"
                id="premium"
                onClick={setActiveTab}
                data-toggle="tab"
                href="#premium"
              >
                Premium Resources
              </a>
            </li>
          </ul>
          <Items />
          {/*
          <div className="advert">
            <img src={advert} alt="ad"/>
            <div className="details">
              <h1>Get the Contrast Bundle</h1>
              <div>
                <span>$400</span>
                <span>$299</span>
                <span>20% off</span>
              </div>
              <span className="span">Get Now</span>
            </div>
          </div>
          <div className="tab-content" style={{marginTop:"40px"}}>
            <div className="row res active" id="free">
              <div className="column h-100">
                <ThemeCard>
                  <Link to="/product/bootstrap-contrast">
                    <div className="theme-image">
                      <img src={card1} alt="Theme" />
                    </div>
                  </Link>
                  <div className="theme-body">
                    <div className="theme-caption">
                      <h3>Contrast Design Bootstrap (Free)</h3>

                      <p>
                        Elegant UI Kit and reusable components for building mobile-first, responsive
                        webistes and web apps
                      </p>
                    </div>
                    <Link to="/docs/contrast/javascript/Installation">
                      <div
                        className="theme-button"
                        style={{ background: '#ccc9ff', color: '#3329e0', border: 'none' }}
                      >
                        Download
                      </div>
                    </Link>
                  </div>
                  <div className="theme-footer">
                    <div className="theme-badges">
                      <Link to="/docs/contrast/javascript/index">
                        <span>Docs</span>{' '}
                      </Link>
                      <Link to="https://contrast-bootstrap-demo.netlify.app">
                        <span>Preview</span>
                      </Link>
                    </div>
                    <Link to="/product/bootstrap-contrast" style={{ maxWidth: '100px' }}>
                      <h3>More Details</h3>
                    </Link>
                  </div>
                </ThemeCard>
              </div>
            </div>
            <div className="row res active" id="premium">
              <div className="column h-100">
                <ThemeCard>
                  <Link to="/product/bootstrap-contrast-pro">
                    <div className="theme-image">
                      <img src={card2} alt="Theme" />
                    </div>
                  </Link>
                  <div className="theme-body">
                    <div className="theme-caption">
                      <h3>Contrast Design Bootstrap (Pro)</h3>

                      <p>
                        Elegant UI Kit and reusable components for building mobile-first, responsive
                        webistes and web apps
                      </p>
                    </div>
                    <Link to="#">
                      <div
                        className="theme-button"
                        style={{ background: '#ccc9ff', color: '#3329e0', border: 'none' }}
                      >
                        Download
                      </div>
                    </Link>
                  </div>
                  <div className="theme-footer">
                    <div className="theme-badges">
                      <Link to="/docs/contrast/javascript/index">
                        <span>Docs</span>{' '}
                      </Link>
                      <Link to="https://contrast-bootstrap-demo.netlify.app">
                        <span>Preview</span>
                      </Link>
                    </div>
                    <Link to="/product/bootstrap-contrast-pro" style={{ maxWidth: '100px' }}>
                      <h3>More Details</h3>
                    </Link>
                  </div>
                </ThemeCard>
              </div>
            </div>
            <div className="row res active" id="free">
              <div className="column h-100">
                <ThemeCard>
                  <Link to="/product/bootstrap-admin-contrast">
                    <div className="theme-image">
                      <img src={card3} alt="Theme" />
                    </div>
                  </Link>
                  <div className="theme-body">
                    <div className="theme-caption">
                      <h3>Contrast Bootstrap Admin Dashboard (Free)</h3>

                      <p>
                        Beautifully designed, responsive admin dashboards to power your admin
                        websites and apps. Built with today's standards and developed with the best
                        tools
                      </p>
                    </div>
                    <a
                      href="#"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div
                        className="theme-button"
                        style={{ background: '#ccc9ff', color: '#3329e0', border: 'none' }}
                      >
                        Download
                      </div>
                    </a>
                  </div>
                  <div className="theme-footer">
                    <div className="theme-badges">
                      <Link to="https://contrast-bootstrap-adminfree.netlify.app">
                        <span>Preview</span>
                      </Link>
                    </div>
                    <Link to="/product/bootstrap-admin-contrast" style={{ maxWidth: '100px' }}>
                      <h3>More Details</h3>
                    </Link>
                  </div>
                </ThemeCard>
              </div>
            </div>
            <div className="row res active" id="premium">
              <div className="column h-100">
                <ThemeCard>
                  <Link to="/product/bootstrap-contrast-pro">
                    <div className="theme-image">
                      <img src={card4} alt="Theme" />
                    </div>
                  </Link>
                  <div className="theme-body">
                    <div className="theme-caption">
                      <h3>Contrast Bootstrap Admin Dashboard (Pro)</h3>

                      <p>
                        Beautifully designed, responsive admin dashboards to power your admin
                        websites and apps. Built with today's standards and developed with the best
                        tools
                      </p>
                    </div>
                    <Link to="#">
                      <div
                        className="theme-button"
                        style={{ background: '#ccc9ff', color: '#3329e0', border: 'none' }}
                      >
                        Download
                      </div>
                    </Link>
                  </div>
                  <div className="theme-footer">
                    <div className="theme-badges">
                      <Link to="https://contrast-bootstrap-adminpro.netlify.app">
                        <span>Preview</span>
                      </Link>
                    </div>
                    <Link to="/product/bootstrap-contrast-pro" style={{ maxWidth: '100px' }}>
                      <h3>More Details</h3>
                    </Link>
                  </div>
                </ThemeCard>
              </div>
            </div>
          </div>
          <div className="tab-content" style={{marginTop:"40px"}}>
            <div className="row res active" id="free">
              <div className="column h-100">
                <ThemeCard>
                  <Link to="/product/jquery-contrast">
                    <div className="theme-image">
                      <img src={card1} alt="Theme" />
                    </div>
                  </Link>
                  <div className="theme-body">
                    <div className="theme-caption">
                      <h3>Contrast Design Bootstrap Jquery (Free)</h3>

                      <p>
                        Elegant UI Kit and reusable components for building mobile-first, responsive
                        webistes and web apps
                      </p>
                    </div>
                    <Link to="/docs/contrast/jquery/Installation">
                      <div
                        className="theme-button"
                        style={{ background: '#ccc9ff', color: '#3329e0', border: 'none' }}
                      >
                        Download
                      </div>
                    </Link>
                  </div>
                  <div className="theme-footer">
                    <div className="theme-badges">
                      <Link to="/docs/contrast/jquery/index">
                        <span>Docs</span>{' '}
                      </Link>
                      <Link to="https://cdbreactdemo.netlify.app/">
                        <span>Preview</span>
                      </Link>
                    </div>
                    <Link to="/product/contrast" style={{ maxWidth: '100px' }}>
                      <h3>More Details</h3>
                    </Link>
                  </div>
                </ThemeCard>
              </div>
            </div>
            <div className="row res active" id="premium">
              <div className="column h-100">
                <ThemeCard>
                  <Link to="/product/contrast-pro">
                    <div className="theme-image">
                      <img src={card2} alt="Theme" />
                    </div>
                  </Link>
                  <div className="theme-body">
                    <div className="theme-caption">
                      <h3>Contrast Design Bootstrap Jquery (Pro)</h3>

                      <p>
                        Elegant UI Kit and reusable components for building mobile-first, responsive
                        webistes and web apps
                      </p>
                    </div>
                    <Link to="#">
                      <div
                        className="theme-button"
                        style={{ background: '#ccc9ff', color: '#3329e0', border: 'none' }}
                      >
                        Download
                      </div>
                    </Link>
                  </div>
                  <div className="theme-footer">
                    <div className="theme-badges">
                      <Link to="/docs/contrast/react/index">
                        <span>Docs</span>{' '}
                      </Link>
                      <Link to="https://cdbreactdemo.netlify.app/">
                        <span>Preview</span>
                      </Link>
                    </div>
                    <Link to="/product/contrast-pro" style={{ maxWidth: '100px' }}>
                      <h3>More Details</h3>
                    </Link>
                  </div>
                </ThemeCard>
              </div>
            </div>
            <div className="row res active" id="free">
              <div className="column h-100">
                <ThemeCard>
                  <Link to="/product/admin-contrast">
                    <div className="theme-image">
                      <img src={card3} alt="Theme" />
                    </div>
                  </Link>
                  <div className="theme-body">
                    <div className="theme-caption">
                      <h3>Contrast Jquery Admin Dashboard (Free)</h3>

                      <p>
                        Beautifully designed, responsive admin dashboards to power your admin
                        websites and apps. Built with today's standards and developed with the best
                        tools
                      </p>
                    </div>
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/devwares.appspot.com/o/4.1.5%202.zip?alt=media&token=541a58e9-5082-4f8e-a4f7-2bb392280286"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div
                        className="theme-button"
                        style={{ background: '#ccc9ff', color: '#3329e0', border: 'none' }}
                      >
                        Download
                      </div>
                    </a>
                  </div>
                  <div className="theme-footer">
                    <div className="theme-badges">
                      <Link to="https://cdbreact-demo-freefree.netlify.app/">
                        <span>Preview</span>
                      </Link>
                    </div>
                    <Link to="/product/admin-contrast" style={{ maxWidth: '100px' }}>
                      <h3>More Details</h3>
                    </Link>
                  </div>
                </ThemeCard>
              </div>
            </div>
            <div className="row res active" id="premium">
              <div className="column h-100">
                <ThemeCard>
                  <Link to="/product/contrast-pro">
                    <div className="theme-image">
                      <img src={card4} alt="Theme" />
                    </div>
                  </Link>
                  <div className="theme-body">
                    <div className="theme-caption">
                      <h3>Contrast Jquery Admin Dashboard (Pro)</h3>

                      <p>
                        Beautifully designed, responsive admin dashboards to power your admin
                        websites and apps. Built with today's standards and developed with the best
                        tools
                      </p>
                    </div>
                    <Link to="#">
                      <div
                        className="theme-button"
                        style={{ background: '#ccc9ff', color: '#3329e0', border: 'none' }}
                      >
                        Download
                      </div>
                    </Link>
                  </div>
                  <div className="theme-footer">
                    <div className="theme-badges">
                      <Link to="https://cdbreact-pro-demo.netlify.app/">
                        <span>Preview</span>
                      </Link>
                    </div>
                    <Link to="/product/contrast-pro" style={{ maxWidth: '100px' }}>
                      <h3>More Details</h3>
                    </Link>
                  </div>
                </ThemeCard>
              </div>
            </div>
          </div>
          */}
        </div>
      </StyledWrapper>
      <FeaturedIn />
    </Layout>
  );
};

export default ResourcesPage;
